import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/cover',
    name: 'cover',
    component: () => import(/* webpackChunkName: "cover" */ '../views/cover.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/maps',
    name: 'maps',
    props: true,
    component: () => import(/* webpackChunkName: "maps" */ '../views/maps.vue')
  },
  {
    path: '/map/:id',
    name: 'map',
    props: true,
    component: () => import(/* webpackChunkName: "map" */ '../views/map.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
