import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import SpinnerIcon from './components/spinner'

const app = createApp(App).use(router);
app.mount('#app');
app.use(VueAxios, axios);
app.use(router)
app.component('SpinnerIcon', SpinnerIcon)
