<template>
  <router-view v-if="map_data" />
  <div class="mapfooter">
    <span v-if="map_data && map_data.footer_text" v-html="map_data.footer_text">
    </span>
  </div>
</template>

<script>
import '@coreui/coreui/dist/css/coreui.min.css';
import axios from 'axios';

export default {
  name: 'MapApp',
  props: [],
  components: {
  },
  data: function () {
    return {
      map_data: null,
      maps: null,
      groups: null,
    };
  },
  computed: {
    show_cover: function () {
      if (this.$route.path == '/') {
        return this.map_data && this.map_data.cover;
      } else {
        return false;
      }
    },
  },
  methods: {
    api_get: function(url) {
      return axios.get(url);
    },
    api_post: function(url, data) {
      return axios.post(url, data);
    },
    init: async function() {
      var r = await this.api_get("/api/v1/map-data/");
      document.title = r.data.title;
      if (r.data.background) {
        document.querySelector('body').style.backgroundImage = "url(" + r.data.background + ")";
      }
      this.map_data = r.data;
    },
    load_maps: async function() {
      if (this.maps && this.groups) return;
      var r1 = await this.$root.api_get("/api/v1/map/");
      var r2 = await this.$root.api_get("/api/v1/group/");
      this.maps = r1.data.objects;
      this.groups = r2.data.objects;
    },
  },
  mounted() {
    this.init();
  },
}
</script>

<style>
#app {
  height: 100%;
}
</style>
